import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from "./pages/home"
import Help from "./pages/help"
import CardContent from "./content/card-content";
import setores from "./content/department";
import TemplateDepartment from "./pages/department";
import React from "react";


function RoutesApp () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {
          setores.map((dict) => (
            <Route key={dict.id} path={`/${dict.link}`} element={TemplateDepartment(dict, CardContent)}></Route>
          ))
        }
        {
          CardContent.map((dict) => (
            <Route key={dict.id} path={`/${dict.link}`} element={Help(dict.title, dict.setor, dict.children ?? [])}></Route>
          ))
        }

        <Route path="/*" element={<Navigate to="/"/>}></Route>
      </Routes>
    </BrowserRouter>
  )
}
export default RoutesApp;
