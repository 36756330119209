import { i18n } from '../translate/i18n'

const setores = [
  {
    id: 3,
    department: i18n.t('content.department.department3'),
    description: '',
    link: 'ditie',
    img: 'ditie',
    inProgress: false,
  },
  {
    id: 5,
    department: i18n.t('content.department.department5'),
    description: i18n.t('information.development'),
    link: '',
    img: 'legal',
    inProgress: true,
  },
  {
    id: 4,
    department: i18n.t('content.department.department4'),
    description: i18n.t('information.development'),
    link: '',
    img: 'dafc',
    inProgress: true,
  },
]

export default setores;
