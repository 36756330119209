import * as React from 'react';
import { Drawer, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Icon from '../icon';
import Translate from '../translate';
import OpenInNewTab from '../../functions/new-tab';
import { i18n } from '../../translate/i18n';

export default function Navigation() {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Grid item>
      <IconButton onClick={toggleDrawer(true)} >
        <Icon name="MenuRounded"></Icon>
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor='right'>
        <Grid container width={{xs:"100vw", sm:"350px"}} padding={2} rowGap={"12px"} justifyContent={"end "}>
          <IconButton onClick={toggleDrawer(false)}>
            <Icon name="ChevronRightRounded" />
          </IconButton>
          <Translate />
        </Grid>
        <Divider></Divider>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={()=>OpenInNewTab("https://grupojacto.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&statuses=open")}>
              <ListItemIcon>
                <Icon name="FormatListBulletedRounded" />
              </ListItemIcon>
              <ListItemText primary={i18n.t("home.request")}/>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Grid>
  );
}
