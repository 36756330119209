import React from 'react';
import Icon from '../components/icon';

import { i18n } from '../translate/i18n';

const CardContent = [
  {
    id: 1,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title1'),
    subtitle: i18n.t('content.subtitle.subtitle1'),
    link: 'DITIE1',
    icon: <Icon name="SmartphoneRounded" />,
    img: './ditie.ng',
    children: [
      { id: 1, title: i18n.t('children.children1'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/52', img: <Icon name="KeyRounded"/> },
      { id: 2, title: i18n.t('children.children2'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/53', img: <Icon name="KeyOffRounded"/> },
      { id: 3, title: i18n.t('children.children3'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/30', img: <Icon name="LockRounded" /> },
      //{ id: 4, title: i18n.t('children.children4'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/54', img: <Icon name="LockOpenRounded" /> },
      { id: 5, title: i18n.t('children.children5'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/55', img: <Icon name="RestorePageRounded" /> },
      { id: 7, title: i18n.t('children.children7'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/57', img: <Icon name="ErrorRounded" /> },
      { id: 133, title: i18n.t('children.children133'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/133', img: <Icon name="UsbRounded" /> },
    ]
  },
  {
    id: 2,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title2'),
    subtitle: i18n.t('content.subtitle.subtitle2'),
    link: 'DITIE2',
    icon: <Icon name="DesktopMacRounded" />,
    img: './ditie.ng',
    children: [
      { id: 8, title: i18n.t('children.children8'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/31', img: <Icon name="ConstructionRounded" /> },
      { id: 9, title: i18n.t('children.children9'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/32', img: <Icon name="DownloadRounded" /> },
      { id: 10, title: i18n.t('children.children10'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/45', img: <Icon name="DesktopMacRounded" /> },
      { id: 11, title: i18n.t('children.children11'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/46', img: <Icon name="AppSettingsAltRounded" /> },
      { id: 12, title: i18n.t('children.children12'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/47', img: <Icon name="AddShoppingCartRounded" /> },
      { id: 13, title: i18n.t('children.children13'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/48', img: <Icon name="HeadsetMicRounded" /> },
      { id: 14, title: i18n.t('children.children14'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/49', img: <Icon name="BrowserUpdatedRounded" /> },
      { id: 15, title: i18n.t('children.children15'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/50', img: <Icon name="PrintRounded" /> },
      { id: 16, title: i18n.t('children.children16'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/70', img: <Icon name="SettingsRounded" /> },
    ]
  },
  {
    id: 3,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title3'),
    subtitle: i18n.t('content.subtitle.subtitle3'),
    link: 'DITIE3',
    icon: <Icon name="WifiRounded" />,
    img: './ditie.ng',
    children: [
      { id: 17, title: i18n.t('children.children17'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/51', img: <Icon name="WifiRounded" /> },
      { id: 18, title: i18n.t('children.children18'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/34', img: <Icon name="SettingsInputComponentRounded" /> },
      { id: 19, title: i18n.t('children.children19'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/33', img: <Icon name="WifiOffRounded" /> },
    ]
  },
  {
    id: 4,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title4'),
    subtitle: i18n.t('content.subtitle.subtitle4'),
    link: 'DITIE4',
    icon: <Icon name="ConstructionRounded" />,
    img: './ditie.ng',
    children: [
      { id: 132, title: i18n.t('children.children132'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/132', img: <Icon name="LiveHelpRounded" /> },
      { id: 20, title: i18n.t('children.children20'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/37', img: <Icon name="GroupsRounded" /> },
      { id: 21, title: i18n.t('children.children21'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/38', img: <Icon name="StorageRounded" /> },
      { id: 22, title: i18n.t('children.children22'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/39', img: <Icon name="LiveHelpRounded" /> },
      { id: 23, title: i18n.t('children.children23'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/41', img: <Icon name="EmailRounded" /> },
      { id: 24, title: i18n.t('children.children24'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/42', img: <Icon name="EmailRounded" /> },
      { id: 25, title: i18n.t('children.children25'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/43', img: <Icon name="RoomPreferencesRounded" /> },
      { id: 69, title: i18n.t('children.children69'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/69', img: <Icon name="RoomPreferencesRounded" /> },
    ]
  },
  {
    id: 5,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title5'),
    subtitle: i18n.t('content.subtitle.subtitle5'),
    link: 'DITIE5',
    icon: <Icon name="LocalPhoneRounded" />,
    img: './ditie.ng',
    children: [
      //{ id: 26, title: i18n.t('children.children26'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3', img: <Icon name="PhoneForwardedRounded" /> },
      { id: 130, title: i18n.t('children.children130'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/130', img: <Icon name="PhoneAndroidRounded" /> },
      { id: 27, title: i18n.t('children.children27'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/61', img: <Icon name="AddIcCallRounded" /> },
      { id: 28, title: i18n.t('children.children28'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/62', img: <Icon name="WifiCalling3Rounded" /> },
      { id: 29, title: i18n.t('children.children29'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/63', img: <Icon name="PhoneDisabledRounded" /> },
      { id: 30, title: i18n.t('children.children30'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/64', img: <Icon name="AutorenewRounded" /> },
    ]
  },
  {
    id: 6,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title6'),
    subtitle: i18n.t('content.subtitle.subtitle6'),
    link: 'DITIE6',
    icon: <Icon name="GroupsRounded" />,
    img: './ditie.ng',
    children: [
      { id: 31, title: i18n.t('children.children31'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/40', img: <Icon name="AddCommentRounded" /> },
      { id: 32, title: i18n.t('children.children32'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/65', img: <Icon name="CancelRounded" /> },
      { id: 33, title: i18n.t('children.children33'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/66', img: <Icon name="MoveDownRounded" /> },
      { id: 34, title: i18n.t('children.children34'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/67', img: <Icon name="AssessmentRounded" /> },
      //{ id: 35, title: i18n.t('children.children35'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3', img: <Icon name="ShoppingCartRounded" /> },
    ]
  },
  {
    id: 7,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title7'),
    subtitle: i18n.t('content.subtitle.subtitle7'),
    link: 'DITIE7',
    icon: <Icon name="SettingsSuggestRounded" />,
    img: './ditie.ng',
    children: [
      { id: 36, title: i18n.t('children.children36'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/26', img: <Icon name="DevicesRounded" /> },
      { id: 37, title: i18n.t('children.children37'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/27', img: <Icon name="SettingsRounded" /> },
      { id: 38, title: i18n.t('children.children38'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/28', img: <Icon name="BugReportRounded" /> },
      { id: 68, title: i18n.t('children.children68'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/68', img: <Icon name="ShoppingCartRounded" /> },
    ]
  },
  {
    id: 8,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title8'),
    subtitle: i18n.t('content.subtitle.subtitle8'),
    link: 'DITIE8',
    icon: <Icon name="MeetingRoomRounded" />,
    img: './ditie.ng',
    children: [
      { id: 39, title: i18n.t('children.children39'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/35', img: <Icon name="MeetingRoomRounded" /> },
      { id: 40, title: i18n.t('children.children40'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/36', img: <Icon name="CelebrationRounded" /> },
    ]
  },
  {
    id: 9,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title9'),
    subtitle: i18n.t('content.subtitle.subtitle9'),
    link: 'DITIE9',
    icon: <Icon name="GppGoodRounded" />,
    img: './ditie.ng',
    children: [
      { id: 41, title: i18n.t('children.children41'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/58', img: <Icon name="PhishingRounded" /> },
      //{ id: 42, title: i18n.t('children.children42'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3', img: <Icon name="MapsUgcRounded" /> },
    ]
  },
  {
    id: 10,
    setor: i18n.t('content.department.department3'),
    highlight: true,
    title: i18n.t('content.title.title10'),
    subtitle: i18n.t('content.subtitle.subtitle10'),
    link: 'DITIE10',
    icon: <Icon name="SupportAgentRounded" />,
    img: './ditie.ng',
    children: [
      { id: 43, title: i18n.t('children.children43'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/73', img: <Icon name="VideocamOffRounded" /> },
      { id: 44, title: i18n.t('children.children44'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/72', img: <Icon name="SmsFailedRounded" /> },
      { id: 45, title: i18n.t('children.children45'), link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/create/71', img: <Icon name="SettingsRounded" /> },
    ]
  },
  {
    id: 11,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'Juridico1',
  },
  {
    id: 12,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'Juridico',
  },
  {
    id: 13,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/7',
  },
  {
    id: 14,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/9',
  },
  {
    id: 15,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/13',
  },
  {
    id: 16,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/15',
  },
  {
    id: 17,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/5',
  },
  {
    id: 18,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/8',
  },
  {
    id: 19,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Juridico',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/12',
  },
  {
    id: 20,
    setor: i18n.t('content.department.department5'),
    highlight: true,
    title: 'Suporte Técnico - Ônix',
    subtitle: 'content.subtitle.subtitle1',
    link: 'https://grupojacto.atlassian.net/servicedesk/customer/portal/3/group/17',
  },
]

const children = CardContent.map((dict) => dict.children || [])
export const AllChildren = children.flat();

export default CardContent;
