import { CardActionArea, Grid, Typography } from '@mui/material';
import tema from '../../styles/theme';
import Icon from '../icon';

function DepartmentsCard({ title, description, link, img, inProgress }) {
    function IsInProgress() {
        if (inProgress) {
            return ` linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 65%, rgba(0, 0, 0, 0.50) 94.9%), url(${img}.webp)`
        }
        return `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 48.38%, rgba(0, 0, 0, 0.60) 94.9%), url(${img}.webp)`
    }

    return (
        <Grid height={{ xs: "180px", sm:"250px",  md: "340px", xl: "430px" }} sx={{ borderRadius: 3, background: IsInProgress(), backgroundSize: 'cover', backgroundPosition: 'center', gradient: '', }} overflow={'hidden'}   >
            <CardActionArea href={link} >
                <Grid container direction='column' justifyContent={'end'} rowGap={2} padding={2} height={{ xs: "180px", sm:"250px", md: "340px", xl: "430px" }}>
                    <Grid container direction='row' justifyContent={'space-between'} alignItems={'end'} height='auto' overflow='hidden' textOverflow='ellipsis'>
                        <Grid direction={'column'}>
                            <Typography variant='title_sm' fontWeight={700} color={tema.palette.primary.contrastText}>{title}</Typography>
                            <Typography variant='subtitle2' color={tema.palette.primary.contrastText}>{description}</Typography>
                        </Grid>
                        <Icon name = "ArrowForwardRounded" sx={{color:"primary.contrastText"}} />
                    </Grid>
                </Grid>
            </CardActionArea>
        </Grid>
    );
}
export default DepartmentsCard;

//background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 48.38%, rgba(0, 0, 0, 0.60) 94.9%), url(<path-to-image>) lightgray 50% / cover no-repeat;
//background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 65%, rgba(0, 0, 0, 0.50) 94.9%), url(<path-to-image>) lightgray -10.165px 0px / 138.835% 100% no-repeat, #FFF;
