// No início do seu arquivo, você teria o componente Icon definido assim:
import * as Icons from "@mui/icons-material";

export default function Icon({ name, ...props }) {
  const IconComponent = Icons[name] || Icons[name="QuestionMarkRoundedIcon"];
  return <IconComponent 
  sx={{ color: "primary.main" }} 
  fontSize="large" 
  {...props} />;
}


// E então, você usaria o componente Icon da seguinte forma:
/* <Icon
  name="SmartphoneRounded"
  fontSize="large" (Opcional)
  sx={{ color: theme.palette.primary.main }} (Opcional)
/>; */
