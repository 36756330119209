import React from "react";
import { Grid } from "@mui/material";

function Menu() {
  return (
    <Grid container height={"20px"} width={"100vw"}>
      <Grid
        sx={{ backgroundColor: "neutral.dark" }}
        height={"fill"}
        width={"10%"}
      ></Grid>
      <Grid
        sx={{ backgroundColor: "secondary.main" }}
        height={"fill"}
        width={"90%"}
      ></Grid>
    </Grid>
  );
}
export default Menu;
