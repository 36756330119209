import * as React from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { AllChildren } from "../../content/card-content";
import { Link } from "@mui/material";
import { i18n } from "../../translate/i18n";
import OpenInNewTab from "../../functions/new-tab";
import SaveHistory from "../../functions/save-history"

export default function AutoComplete() {
  const handleClick = () => { };

  return (

      <Autocomplete
      noOptionsText={i18n.t("autocomplete.no_options")}
        disablePortal
        id="search"
        options={AllChildren}
        getOptionLabel={(option) => `${option.title} `}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Link onClick={() => {OpenInNewTab(option.link); SaveHistory(option.id); window.location.reload()}} underline="none">
              


              <Box
              typography={'body2'}
              key={key}
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...optionProps}
                columnGap={2}
                onClick={handleClick}
                >
                {option.img}
                {option.title}
              </Box>
            </Link>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label={i18n.t("autocomplete.forms")} />
        )}
      />
  );
}
