import { createTheme } from "@mui/material";



const theme = createTheme({
  palette: {
    primary: {
      light: "#4F6CB8",
      main: "#1E4382",
      dark: "#172C5B",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#F15B4E",
      main: "#F15B4E",
      dark: "#F15B4E",
      contrastText: "#FFFFFF",
    },
    neutral: {
      light: "#F3F3F3",
      main: "rgba(54, 54, 54, 0.7)",
      dark: "#646464",
      contrastText: "#1E1E1E",
    },
    text: {
      main: "#040404",
      light: "#FFFFFF99",
      dark: "#363636B3",
    },
    background: {
      white: "#FFFFFF",
      black: "#000000",
    },
    box: {
      shadow: "0px 4px 2px 0px #00000010, 0px 4px 12px 0px #1018281B",
    },
  },


  typography: {
    title: {
      fontWeight: 700,
      fontFamily: "UJI DISPLAY",
      fontStyle: "normal",
      lineHeight: "normal",
      leadingTrim: "both",
      textEdge: "cap",
      fontSize: "28px",
      "@media (min-width:1200px)": {
        fontSize: "48px",
      },
    },
    title_sm: {
      fontWeight: 400,
      fontFamily: "UJI DISPLAY",
      fontSize: "28px",
      fontStyle: "normal",
      lineHeight: "normal",
      colors: "primery.contrastText",
      leadingTrim: "both",
      textEdge: "cap",
    },

    h1: {
      fontFamily: "Inter",
    },
    h2: {
      fontFamily: "Inter",
    },
    h4: {
      fontFamily: "Inter",
    },
    h5: {
      fontFamily: "Inter",
    },
    h6: {
      fontFamily: "Inter",
    },
    subtitle1: {
      fontFamily: "Inter",
    },
    subtitle2: {
      fontFamily: "Inter",
    },
    body1: {
      fontFamily: "Inter",
    },
    body2: {
      fontFamily: "Inter",
    },
    button: {
      fontFamily: "Inter",
    },
    caption: {
      fontFamily: "Inter",
    },
    overline: {
      fontFamily: "Inter",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: "h3",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'UJI DISPLAY';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('./assets/fonts/UJIDISPLAY-BOLD.otf') format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export default theme;
