import React, { useState, useEffect } from "react";
import { Button, IconButton, Snackbar, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { i18n } from "../../translate/i18n";

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallBar, setShowInstallBar] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallBar(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
        setShowInstallBar(false);
      });
    }
  };

  const handleCloseBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowInstallBar(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="large" onClick={handleInstallClick}>
        {i18n.t("install_pwa.install")}
      </Button>
      <IconButton
        aria-label="close"
        color="secondary"
        size="small"
        onClick={handleCloseBar}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  );

  const SlideTransition = (props) => <Slide {...props} direction="up" />;

  return (
    <Snackbar
      open={showInstallBar}
      onClose={handleCloseBar}
      autoHideDuration={10000}
      TransitionComponent={SlideTransition}
      message={i18n.t("install_pwa.description")}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      action={action}
      ContentProps={{
        sx: {
          borderRadius: "10px",
          padding: "6px 24px",
          display: "flex",
          flexWrap: "nowrap",
        },
      }}
      sx={{
        bottom: { xs: -8, sm: 8 },
        maxWidth: "calc(100% - 16px)",
      }}
    />
  );
};

export default PWAInstallPrompt;
