import React, {useState}  from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { i18n } from "../../translate/i18n";


export default function Translate () {

    const I18N_STORAGE_KEY = "i18nextLng";
    
    const [language, setLanguage] = useState(
        localStorage.getItem(I18N_STORAGE_KEY)
      );
    
      const handleChange = (event) => {
        window.localStorage.setItem(I18N_STORAGE_KEY, event.target.value);
        setLanguage(event.target.value);
        window.location.reload();
      };

    return (
        <FormControl
            sx={{
                backgroundColor: "primary.contrastText",
                width: { xs: "100%" },
            }}
        >
            <InputLabel id="demo-simple-select-label">
                {i18n.t("translate.language")}
            </InputLabel>
            <Select
                value={language}
                label="Idioma"
                onChange={handleChange}
            >
                <MenuItem value="pt-BR">{i18n.t("translate.pt")}</MenuItem>
                <MenuItem value="en-US">{i18n.t("translate.en")}</MenuItem>
                <MenuItem value="es">{i18n.t("translate.es")}</MenuItem>
            </Select>
        </FormControl>
    )
}