import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import RoutesApp from './routes';
import './index.css';
import theme from './styles/theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

window.name = 'Services Hub';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement || document.createElement('div'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RoutesApp />
    </ThemeProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
