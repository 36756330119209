const HISTORY_STORAGE_KEY = "historycards";

export default function SaveHistory(id) {    
    let localClick =
      window.localStorage.getItem(HISTORY_STORAGE_KEY)?.split(",") || [];
    console.warn({ localClick });
    let pos = localClick.indexOf(id.toString());
    console.log({ localClick, id, pos });
    if (pos >= 0) {
      localClick.splice(pos, 1);
    }
    localClick.push(id);
    window.localStorage.setItem(
      HISTORY_STORAGE_KEY,
      localClick.slice(-6).join(",")
    );
  };