import React from "react";
import { CardActionArea, CardContent, Paper, Typography, Tooltip} from "@mui/material";
import theme from "../../styles/theme";
import OpenInNewTab from '../../functions/new-tab'

function ContactCard({ title, link, img, handleClick }) {


  return (
    <Paper
      container
      borderRadius={3}
      sx={{
        backgroundColor: "background.white",
        boxShadow: theme.palette.box.shadow,
      }}
      alignItems={"center"}
      onClick={handleClick}
    >
      <CardActionArea onClick={() => OpenInNewTab(link)}>
        <CardContent>
          {img}
          <Tooltip title={title}>
            <Typography
              variant="subtitle1"
              style={{
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {title}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Paper>
  );
}
export default ContactCard;

//href={link}
