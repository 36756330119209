import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import setores from "../../content/department";
import { i18n } from "../../translate/i18n";
import DepartmentsCard from "../../components/department-card";
import AutoComplete from "../../components/auto-complete";
import ContactCard from "../../components/contact-card";
import { AllChildren } from "../../content/card-content";
import Menu from "../../components/menu/index.";
import PWAInstallPrompt from "../../components/install-pwa";
import SaveHistory from "../../functions/save-history";
import Icon from "../../components/icon";
import Navigation from "../../components/navigation";

function Departments(index, title, description, link, img, inProgress) {
  return (
    <Grid item xs={12} sm={4}>
      <DepartmentsCard
        key={index}
        title={title}
        description={description}
        link={link}
        img={img}
        inProgress={inProgress}
      ></DepartmentsCard>
    </Grid>
  );
}

function Home() {

  const savedHistory =
    window.localStorage.getItem("historycards")?.split(",") || [];
  const filteredCards = savedHistory
    .map((id) => AllChildren.find((child) => child.id.toString() === id))
    .slice(-6)
    .reverse();


  return (
    <Grid>
      <PWAInstallPrompt />
      <Menu></Menu>
      <Grid
        sx={{ overflowX: "hidden" }}
        rowGap={4}
        container
        paddingBlock={6}
        paddingInline={{ xs: "5%", md: "10%" }}
      >
        <Grid
          sx={{
            zIndex: -1,
            backgroundImage: "url(grafismo.png)",
            opacity: "0.4",
            backgroundSize: "cover",
            overflowY: "none",
          }}
          position={"absolute"}
          top={"-350px"}
          right={"0"}
          width={"38vw"}
          height={"60vw"}
        ></Grid>

        <Grid container rowGap={2} direction={{xs:"column-reverse", md:"row"}} justifyContent={"space-between"}>
          <Grid id="logo" direction={"column"} rowGap={1}>
            <Grid
              sx={{
                backgroundImage: "url(logo.svg)",
                backgroundSize: "cover",
              }}
              width={{ sx: "fill", sm: "420px" }}
              height={{ xs: "0", sm: "40px" }}
              paddingBottom={{ xs: "10%", sm: "0" }}
            />
            <Typography color="neutral.main" variant="subtitle1">
              {i18n.t("home.description")}
            </Typography>
          </Grid>
          <Navigation></Navigation>
        </Grid>

        <Grid xs={12} sm={4} sx={{ backgroundColor: "primary.contrastText" }}>
          <AutoComplete></AutoComplete>
        </Grid>

        <Grid container rowGap={2}>

          <Typography variant="h5">{i18n.t("home.portals")}</Typography>
          <Grid container spacing={2} height={"fit-content"}>
            {setores.map((dict, index) =>
              Departments(
                index,
                dict.department,
                dict.description,
                dict.link,
                dict.img,
                dict.inProgress
              )
            )}
          </Grid>

          <Grid container rowGap={2}>
            <Typography variant="h5">{i18n.t("home.forms")}</Typography>

            <Grid container spacing={2} height={"fit-content"}>
              {filteredCards.length > 0 ? (
                filteredCards.map((dict) => (
                  <Grid
                    onClick={() => window.location.reload()}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <ContactCard
                      title={dict?.title}
                      link={dict?.link}
                      img={dict?.img}
                      handleClick={() => SaveHistory(dict.id)}
                    ></ContactCard>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} md={6} lg={4}>
                  <Grid
                    container
                    direction={"column"}
                    rowGap={2}
                    alignItems={"center"}
                    padding={"24px"}
                    boxShadow={"box.shadows"}
                    sx={{
                      borderRadius: 4,
                      backgroundColor: "background.white",
                      border: 2,
                      borderColor: "neutral.light",
                    }}
                  >
                    <Icon name="HistoryRounded" sx={{ color: "neutral.main" }} />
                    <Typography variant="h6" sx={{ color: "neutral.main" }}>
                      {i18n.t("home.no_forms")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Home;
