import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../styles/theme";
import Icon from "../icon";
import React from "react";

function ContentCard({ title, subtitle, link, icon, img }) {
  return (
    <Card
      sx={{
        borderRadius: 4,
        backgroundColor: "background.white",
        boxShadow: theme.palette.box.shadow,
      }}
    >
      <CardActionArea href={link}>
        <CardContent sx={{ height: "190px" }}>
          <Grid container rowGap={2} direction={"column"}>
            {icon}
            <Typography
              variant="h5"
              fontWeight={400}
              gutterBottom
              component="div"
              color={"text.main"}
              style={{
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Tooltip title={subtitle}>
            <Typography
              heivariant="body1"
              color={"primary.main"}
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {subtitle}
            </Typography>
          </Tooltip>
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Icon name="ArrowForwardRounded" sx={{ color: "inherit" }} />
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
export default ContentCard;
