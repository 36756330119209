import React from "react";
import { Grid, Typography, Breadcrumbs, IconButton, Link } from "@mui/material";
import Icon from "../../components/icon";
import theme from "../../styles/theme";
import ContactCard from "../../components/contact-card";
import { i18n } from "../../translate/i18n";
import SaveHistory from "../../functions/save-history"

function Help(title, setor, child) {
  const link = setor.slice(0, -4);

  return (
    <Grid>
      <Grid
        container
        position={"absolute"}
        sx={{
          backgroundImage: `url(background.png)`,
          top: "0px",
          right: "0px",
          zIndex: -100,
        }}
        height={"300px"}
      />
      <Grid container paddingTop={10} paddingInline={{ xs: "0", lg: "10%" }}>
        <Grid
          rowGap={4}
          container
          padding={{ xs: "5%", md: 5 }}
          direction={"column"}
          sx={{
            minHeight: "calc(100vh - 80px)",
            backgroundColor: "#fff",
            borderRadius: "24px 24px 0px 0px",
            boxShadow: theme.palette.box.shadow,
          }}
        >
          <Grid display={{ xs: "none", sm: "block" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color={"text.dark"} href="/">
                {window.name}
              </Link>
              <Link
                underline="hover"
                color={"text.dark"}
                href={`/${link}`}
              >
                {setor}
              </Link>
              <Typography fontWeight={"700"} color="#040404">
                {title}
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid container direction={"row"} sx={{flexFlow:"nowrap"}} alignItems={"baseline"}>

            <IconButton sx={{width:"48px", height:"48px"}} href={`/${link}`} aria-label="ArrowBack" color="inherit">
              <Icon name="ArrowBackRounded" sx={{ color: "inherit" }} />
            </IconButton>

            <Typography
              fontWeight={"bold"}
              variant={"title"}
            >
              {title}
            </Typography>
          </Grid>

          <Grid container gap={2} direction={"column"}>
            <Typography variant="h5">{i18n.t("forms.forms")}</Typography>
            <Grid container spacing={2}>
              {child.map((dict) => (
                <Grid item xs={12} md={6} lg={4}>
                  <ContactCard
                    title={dict.title}
                    link={dict.link}
                    img={dict.img}
                    handleClick={() => SaveHistory(dict.id)}
                  ></ContactCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Help;
