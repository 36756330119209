const messages = {
  es: {
    translations: {
      content: {
        title: {
          title1: 'Acceso a Software, Plataformas, Apps y Portales',
          title2: 'Software y Hardware',
          title3: 'Conexión a Internet y Redes',
          title4: 'Plataforma Office 365',
          title5: 'Servicios de Telefonía',
          title6: 'Gestión de Licencias',
          title7: 'Proyectos y Sistemas',
          title8: 'Salas de Reuniones y Eventos',
          title9: 'Seguridad de la Información',
          title10: 'Soporte Técnico - Ónix',
        },
        subtitle: {
          subtitle1: 'Acceder, revocar acceso, desbloquear inicio de sesión y contraseña, liberar acceso al P7000, restaurar archivos del P7000, restaurar archivos de correo electrónico, informar problemas con el software y hardware del centro de datos',
          subtitle2: 'Instalar o Reparar Software, Instalar Certificado Digital, Cambiar Computadora, Soporte técnico para Smartphones y Tablets, Adquirir nuevos equipos o periféricos, Prestar o devolver equipos, Actualizar o configurar la computadora, Impresoras y Escáneres, Configurar Coleccionistas',
          subtitle3: 'Solicitar nuevas demandas de cobertura de red, Problemas de acceso a la conexión de cable, Problemas de acceso a la red Wi-Fi',
          subtitle4: 'Crear un equipo en Teams, Aumentar el almacenamiento en SharePoint, Soporte técnico de SharePoint, Outlook - Crear cuadro compartido, Outlook - Crear lista de distribución, Outlook - Crear o editar sala de reuniones, Liberar acceso para un empleado desconectado',
          subtitle5: 'Cambiar líneas telefónicas, Recibir soporte para facturas telefónicas, Activar roaming internacional, Mal funcionamiento de la línea telefónica, Cambiar plan telefónico',
          subtitle6: 'Solicitar licencias, eliminar licencias, transferir licencias, informes de licencias, solicitar solicitudes de compra de materiales',
          subtitle7: 'Proyecto Digital y Mejoras, Configuraciones y Ajustes, Errores',
          subtitle8: 'Configurar Sala de Conferencias, Soporte técnico en eventos',
          subtitle9: 'Reportar una amenaza (virus, phishing, malware), Sugerir una mejora en las políticas de Seguridad de la Información',
          subtitle10: 'La cámara no funciona, Falla en el monitoreo de vigilancia, Configurar el monitoreo de seguridad de la propiedad',
        },
        department: {
          department3: 'DITIE (TI)',
          department5: 'Legal',
          department4: 'DAFC',
        },
        description: {
          description3: 'Encuentre respuestas, soporte técnico y desarrolle proyectos con el equipo de TI.',
          description5: 'Encuentre respuestas, soporte técnico y desarrolle proyectos con el equipo Legal.',
          description4: 'Encuentre respuestas, soporte técnico y desarrolle proyectos con el equipo de Finanzas.',
        }
      },
      children: {
        children1: 'Autorizará el acceso',
        children2: 'Revocar el acceso',
        children3: 'Desbloquear inicio de sesión y contraseña',
        children4: 'Liberar acceso a P7000',
        children5: 'Restaurar archivos desde P7000',
        children6: 'Restaurar archivos desde el correo electrónico',
        children7: 'Reportar problemas con el Datacenter',
        children8: 'Instalar o reparar software',
        children9: 'Instalar certificado digital',
        children10: 'Cambiar computadora',
        children11: 'Soporte técnico para Smartphones y Tablets',
        children12: 'Adquirir equipos y accesorios',
        children13: 'Préstamo o devolución de equipo',
        children14: 'Actualiza o configura tu computadora',
        children15: 'Impresoras y escáneres',
        children16: 'Configurar recopiladores',
        children17: 'Solicitar nuevas demandas de cobertura de red',
        children18: 'Problemas de acceso a la conexión de cables',
        children19: 'Problemas de acceso a la red wifi',
        children20: 'Crear equipo en Teams',
        children21: 'Aumentar el almacenamiento en SharePoint',
        children22: 'Soporte técnico de SharePoint',
        children23: 'Outlook - Crear cuadro compartido',
        children24: 'Outlook - Crear lista de distribución',
        children25: 'Outlook: crear o editar una sala de reuniones',
        children26: 'cambiar lineas telefonicas',
        children27: 'Recibe soporte sobre facturas telefónicas',
        children28: 'Activar roaming internacional',
        children29: 'Mal funcionamiento de la línea telefónica',
        children30: 'Cambiar plan telefónico',
        children31: 'Solicitar Licencias',
        children32: 'Quitar de licencias',
        children33: 'Transferir licencias',
        children34: 'Informes de licencia',
        children36: 'Proyectos y Mejoras Digitales',
        children37: 'Configuraciones y ajustes',
        children38: 'Informar un fallo del sistema o de la plataforma',
        children39: 'Configurar sala de conferencias',
        children40: 'Soporte técnico en eventos.',
        children41: 'Informar una amenaza (virus, phishing, malware)',
        children42: 'Sugerir una mejora en las políticas de Seguridad',
        children43: 'La cámara no funciona',
        children44: 'Fallo de monitoreo de vigilancia',
        children45: 'Configurar el monitoreo de seguridad de activos',
        children68: 'Solicitar una solicitud de compra de material o servicio',
        children69: 'Liberar accesos de empleado desconectado',
        children130: 'Solicitar e-SIM',
        children132: 'Soporte de plataforma en línea Office 365',
        children133: 'Puerto USB libre',
      },
      forms: {
        access_forms: 'Accede a los formularios:',
        forms: 'Formas:',
        popular: 'Formas populares:',
        unpopular: 'Otras formas:',
      },
      home: {
        description: '¡Hola! ¡Encuentre respuestas, soporte y haga sus preguntas con nuestro equipo!',
        portals: 'Portales:',
        forms: 'Formularios accedidos recientemente:',
        no_forms: 'Sin acceso reciente',
        request: 'peticiones',
      },
      translate: {
        language: 'Idioma',
        pt: 'Português - BR',
        en: 'English - US',
        es: 'Español - ES',
      },
      autocomplete: {
        forms: 'Formulario de búsqueda',
        no_options: 'Formulario no encontrado',
      },
      information:{
        development: 'En desarrollo',
      },
      install_pwa: {
        title: 'Instalar Aplicación',
        description: 'Instala la aplicación en tu dispositivo',
        install: 'Instalar',
        close: 'Cerrar',
      },
    }
  }
}

export { messages }
