import React from "react";
import { Grid, Typography, Link, Breadcrumbs, IconButton } from "@mui/material";
import ContentCard from "../../components/content-card";
import ContactCard from "../../components/contact-card";
import { i18n } from "../../translate/i18n";
import theme from "../../styles/theme";
import Icon from "../../components/icon";

/**
 * @param {React.Key | null | undefined} index
 * @param {any} setor
 * @param {any} title
 * @param {any} subtitle
 * @param {any} link
 * @param {any} icon
 * @param {any} img
 * @param {boolean} highlight
 * @param {boolean} boolean
 * @param {any} department
 */
function isHighlight(
  index,
  setor,
  title,
  subtitle,
  link,
  icon,
  img,
  highlight,
  boolean,
  department
) {
  if (highlight && boolean && setor === department) {
    return (
      <Grid item xs={12} sm={6} lg={3}>
        <ContentCard
          key={index}
          title={title}
          subtitle={subtitle}
          link={link}
          icon={icon}
          img={img}
        ></ContentCard>
      </Grid>
    );
  } else if (highlight === false && boolean === false && setor === department)
    return (
      <Grid item xs={12} sm={6} lg={4}>
        <ContactCard
          key={index}
          title={title}
          link={link}
        ></ContactCard>
      </Grid>
    );
}

function TemplateDepartment(department, content) {
  return (
    <Grid>
      <Grid
        container
        position={"absolute"}
        sx={{
          backgroundImage: `url(background.png)`,
          top: "0px",
          right: "0px",
          zIndex: -100,
        }}
        height={"300px"}
      />
      <Grid paddingTop={10} paddingInline={{xs:"0",lg:"10%"}}>
        <Grid
          rowGap={4}
          container
          padding={{xs:"5%", md: 5}}
          direction={"column"}
          sx={{
            minHeight: "calc(100vh - 80px)",
            backgroundColor: "#fff",
            borderRadius: "24px 24px 0px 0px",
            boxShadow: theme.palette.box.shadow,
          }}
        >
          <Grid display={{ xs: "none", sm: "block" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                {window.name}
              </Link>
              <Typography fontWeight={"600"} color={"text.main"}>
                {department.department}{" "}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid container rowGap={1} xs={12} md={9} lg={10}>
            <Grid container columnGap={1} alignItems={"center"}>
              <Grid>
                <Link href={`/`} color="inherit">
                  <IconButton aria-label="ArrowBack" color="inherit">
                  <Icon name = "ArrowBackRounded" sx={{color:"inherit"}} />
                  </IconButton>
                </Link>
              </Grid>
              <Typography variant="title">
                {department.department}
              </Typography>
            </Grid>
            <Typography color="rgba(54, 54, 54, 0.4)" variant="subtitle1">
              {department.description}
            </Typography>
          </Grid>
          <Grid container gap={2} direction={"column"}>
            <Typography variant="h5">{i18n.t("forms.access_forms")}</Typography>
            <Grid container spacing={2}>
              {content.map((dict, index) =>
                isHighlight(
                  index,
                  dict.setor,
                  dict.title,
                  dict.subtitle,
                  dict.link,
                  dict.icon,
                  dict.img,
                  dict.highlight,
                  true,
                  department.department
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TemplateDepartment;
